import { createSlice } from '@reduxjs/toolkit';


const sopStaticDataSlice = createSlice({
  name: 'sopStaticData',
  initialState :{
    previousPageState: false,
    checklist: {
      selectedFields: [],
      data : {
        title: "",
        description : "",
        company : "Trident Group",
        business : "",
        department : "",
        function : "",
        active : false,
        tags : "",
        attachmentComment : "",
        attachment : false,
        template : "BASIC",
        fieldToShow : [],
        sections: []
      }
    },
    sop: {
      selectedFields: [],
      data : {
        title: "",
        description : "",
        company : "Trident Group",
        business : "",
        department : "",
        function : "",
        active : false,
        tags : "",
        attachmentComment : "",
        attachment : false,
        template : "BASIC",
        fieldToShow : [],
        sections: []
      }
    },
    manual: {
      selectedFields: [],
      data : {
        title: "",
        description : "",
        company : "Trident Group",
        business : "",
        department : "",
        function : "",
        active : false,
        tags : "",
        attachmentComment : "",
        attachment : false,
        template : "BASIC",
        fieldToShow : [],
        sections: []
      }
    }
  },
  reducers: {
    setSelectedFieldsForSOP: (state, action) => {
      const { category, item } = action.payload;
      if (state[category]) {
        state[category].selectedFields = item; 
        if (category === "manual"){
          state[category].data.fieldToShow = item;
        }else{
          state[category].data.fieldToShow = item;
          // state[category].data.sections = item.includes("sections")
          // ? [ 
          //   {
          //     sectionIndex: 0,
          //     sectionName: "",
          //     tasks: [
          //       {
          //         taskIndex: 0,
          //         taskName: "",
          //         tickBox : item.includes("checkbox") && !["LEVEL1", "LEVEL2", "LEVEL3"].some(level => item.includes(level)) ? [{ tickBoxName: "NA", tickBoxIndex: 0 }]: []
          //       },
          //     ],
          //   },
          // ]
          // : [
          //     {
          //       sectionIndex: 0,
          //       sectionName: "NA",
          //       tasks: [
          //         {
          //           taskIndex: 0,
          //           taskName: "",
          //           tickBox : item.includes("checkbox") && !["LEVEL1", "LEVEL2", "LEVEL3"].some(level => item.includes(level)) ? [{ tickBoxName: "NA", tickBoxIndex: 0 }]: []
          //         },
          //       ],
      
          //     },
          //   ]
        }
      }
    },

    setInputDataSOP: (state, action) => {
      const { category, item } = action.payload;
      if (state[category]) {
        // state[category].data = { ...state[category].data, ...item };
        state[category].data = item ;
      }
    },

    resetChecklistData: (state) => {
      state.checklist = { 
        selectedFields: [],
        data : {
          title: "",
          description : "",
          company : "Trident Group",
          business : "",
          department : "",
          function : "",
          active : false,
          tags : "",
          attachmentComment : "",
          attachment : false,
          template : "BASIC",
          fieldToShow : [],
          sections: []
        }
      };
    },

    resetSOPData: (state) => {
      state.sop = {
        selectedFields: [],
        data : {
          title: "",
          description : "",
          company : "Trident Group",
          business : "",
          department : "",
          function : "",
          active : false,
          tags : "",
          attachmentComment : "",
          attachment : false,
          template : "BASIC",
          fieldToShow : [],
          sections: []
        }
      }
    },


    resetManualData: (state) => {
      state.manual = { 
        selectedFields: [],
        data : {
          title: "",
          description : "",
          company : "Trident Group",
          business : "",
          department : "",
          function : "",
          active : false,
          tags : "",
          attachmentComment : "",
          attachment : false,
          template : "BASIC",
          fieldToShow : [],
          sections: []
        }
      };
    },

    setPreviousPageState: (state, action) => {
      state.previousPageState = action.payload; // Store data before navigating
    },
  }
});

export const { setSelectedFieldsForSOP , setInputDataSOP , resetSOPData , resetChecklistData , resetManualData ,   setPreviousPageState 
} = sopStaticDataSlice.actions;
export {sopStaticDataSlice};
