import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";
import STATUSES from "../Statuses";
import config from "../../config";

const serviceURL = config.SERVICE_TRACEABILITY_URL;

// =============================================== Check List APIS ============================================
const CheckListSlice = createSlice({
  name: "CheckList",
  initialState: {
    data: {},
    status: STATUSES.IDEAL,
    error: null,
  },
  reducers: {
    resetCheckListState: (state, action) => {
      state.error = null;
      state.status = STATUSES.IDEAL;
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        saveCheckListData.pending,
        updateCheckListData.pending,
        deleteCheckListData.pending,
        saveUseCheckListData.pending,
        updateUseCheckListData.pending,
        deleteUseCheckListData.pending,
        saveAssignCheckList.pending,
        saveFavoriteCheckList.pending,

        saveSOPData.pending,
        updateSOPData.pending,
        deleteSOPData.pending,
        saveUseSOPData.pending,
        updateUseSOPData.pending,
        deleteUseSOPData.pending,
        saveAssignSOP.pending,
        saveFavoriteSOP.pending,

        saveManualData.pending,
        updateManualData.pending,
        deleteManualData.pending,
        saveUseManualData.pending,
        updateUseManualData.pending,
        deleteUseManualData.pending,
        saveAssignManual.pending,
        saveFavoriteManual.pending,

      ),
      (state) => {
        state.status = STATUSES.LOADING;
      }
    );

    builder.addMatcher(
      isAnyOf(
        saveCheckListData.fulfilled,
        updateCheckListData.fulfilled,
        deleteCheckListData.fulfilled,
        saveUseCheckListData.fulfilled,
        updateUseCheckListData.fulfilled,
        deleteUseCheckListData.fulfilled,
        saveAssignCheckList.fulfilled,
        saveFavoriteCheckList.fulfilled,

        saveSOPData.fulfilled,
        updateSOPData.fulfilled,
        deleteSOPData.fulfilled,
        saveUseSOPData.fulfilled,
        deleteUseSOPData.fulfilled,
        updateUseSOPData.fulfilled,
        saveAssignSOP.fulfilled,
        saveFavoriteSOP.fulfilled,

        saveManualData.fulfilled,
        updateManualData.fulfilled,
        deleteManualData.fulfilled,
        saveUseManualData.fulfilled,
        updateUseManualData.fulfilled,
        deleteUseManualData.fulfilled,
        saveAssignManual.fulfilled,
        saveFavoriteManual.fulfilled,

      ),
      (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
        state.error = null;
      }
    );

    builder.addMatcher(
      isAnyOf(
        saveCheckListData.rejected,
        updateCheckListData.rejected,
        deleteCheckListData.rejected,
        saveUseCheckListData.rejected,
        updateUseCheckListData.rejected,
        deleteUseCheckListData.rejected,
        saveAssignCheckList.rejected,
        saveFavoriteCheckList.rejected,

        saveSOPData.rejected,
        updateSOPData.rejected,
        deleteSOPData.rejected,
        saveUseSOPData.rejected,
        deleteUseSOPData.rejected,
        updateUseSOPData.rejected,
        saveAssignSOP.rejected,
        saveFavoriteSOP.rejected,

        saveManualData.rejected,
        updateManualData.rejected,
        deleteManualData.rejected,
        saveUseManualData.rejected,
        updateUseManualData.rejected,
        deleteUseManualData.rejected,
        saveAssignManual.rejected,
        saveFavoriteManual.rejected,

      ),
      (state, action) => {
        state.error = action.payload;
        state.status = STATUSES.ERROR;
      }
    );
  },
});

//  =========================================  CheckList APIS ============================================

// get data from CheckList
export const getCheckListData = createAsyncThunk(
  "checkList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/checklists/getAllChecklists`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get one data from CheckList
export const getOneCheckListData = createAsyncThunk("oneCheckList/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder} = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params: { page, size, sortBy, sortOrder},
      };

       // Convert array to query string format
       const params = new URLSearchParams();
       data.checklistIds.forEach(id => params.append("checklistIds", id));

      const responce = await axios.get(`${serviceURL}/checklists/by-ids?${params.toString()}`,config);
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// save Data in CheckList
export const saveCheckListData = createAsyncThunk(
  "checkList/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/checklists/createChecklist`,
        data,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update Data in CheckList
export const updateCheckListData = createAsyncThunk(
  "checkList/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { id, ...restData } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.put(
        `${serviceURL}/checklists/${id}`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from CheckList
export const deleteCheckListData = createAsyncThunk(
  "checkList/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/checklists/${data.id}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// =============================================== USE Check List APIS ============================================

// get data from  useCheckList
export const getUseCheckListData = createAsyncThunk(
  "useCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { userId, page, size, sortBy, sortOrder , eventCode} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { userId, page, size, sortBy, sortOrder, eventCode },
      };

      const responce = await axios.get(
        `${serviceURL}/useOfChecklists/getAllUserChecklistRecords`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// save Data in CheckList
export const saveUseCheckListTemplate = createAsyncThunk("useCheckList/createTemplate", async (data, { rejectWithValue, getState }) => {
    try {
      const { checklistId ,scheduleId , eventCode } = data;
      const state = getState();
      const token = state.auth.token;

      const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
        params: { checklistId , eventCode , scheduleId },
      };

      const response = await axios.post(`${serviceURL}/useOfChecklists/useChecklist`, {},config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUseCheckListData = createAsyncThunk(
  "useCheckList/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { files, scheduleId, eventCode, ...restData } = data;
      const state = getState();
      const token = state.auth.token;

      const formData = new FormData();
      formData.append("record", JSON.stringify(restData));

      // Append each file to FormData properly
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      const queryParams = new URLSearchParams();
      if (scheduleId) {
        queryParams.append("scheduleId", scheduleId);
      }
      if (eventCode) {
        queryParams.append("eventCode", eventCode);
      }

      const config = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.post(
        `${serviceURL}/useOfChecklists/${
          restData.id
        }?${queryParams.toString()}`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// update Data in CheckList
export const updateUseCheckListData = createAsyncThunk("useCheckList/update", async (data, { rejectWithValue, getState }) => {
    try {
      const { files, ...restData } = data;
      const state = getState();
      const token = state.auth.token;

      const formData = new FormData();
      formData.append("record", JSON.stringify(restData));

      // Append each file to FormData properly
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      const queryParams = new URLSearchParams();
  
      const config = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.post(
        `${serviceURL}/useOfChecklists/updateExistingUserChecklistRecord/${
          restData.id
        }?${queryParams.toString()}`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from CheckList
export const deleteUseCheckListData = createAsyncThunk(
  "useCheckList/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/useOfChecklists/${data.userId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================ Assign and Favorite checklist API =============================================

// Save Assign CheckList Data
export const saveAssignCheckList = createAsyncThunk(
  "assignCheckList/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { myUserId, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfChecklists/assign`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Save Favorite CheckList Data
export const saveFavoriteCheckList = createAsyncThunk(
  "FavoriteCheckList/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { isRemove, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { isRemove: isRemove },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfChecklists/markAsFavChecklist`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get data from  useCheckList
export const getFavAndAssignedCheckList = createAsyncThunk(
  "favAndAssignedCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.get(
        `${serviceURL}/useOfChecklists/getAllFavAndAssignedChecklists/${data.userId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  =========================================  SOP APIS ==========================================================================================================================================================================================================================================================================================================================

// get data from SOP
export const getSOPData = createAsyncThunk(
  "SOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction},
      };

      const responce = await axios.get(`${serviceURL}/sops/getAllSOPs`, config);
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get one data from SOP
export const getOneSOPData = createAsyncThunk("oneSOP/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, filters } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", },
        params: { page, size, sortBy, sortOrder, filters },
      };

      // Convert array to query string format
      const params = new URLSearchParams();
      data.sopIds.forEach(id => params.append("sopIds", id));

      const responce = await axios.get(`${serviceURL}/sops/by-ids?${params.toString()}`, config);
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// save Data in SOP
export const saveSOPData = createAsyncThunk(
  "SOP/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/createSOP`,
        data,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update Data in SOP
export const updateSOPData = createAsyncThunk(
  "SOP/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { sopId, ...restData } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.put(
        `${serviceURL}/SOPs/${sopId}`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from SOP
export const deleteSOPData = createAsyncThunk(
  "SOP/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/sops/${data.id}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================ SOP Images and PDF ===================================

// upload sop images
export const uploadSOPImg = createAsyncThunk(
  "SOP/UploadImg",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/uploadSOPImage`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// upload sop PDF
export const uploadSOPPdf = createAsyncThunk(
  "SOP/UploadPdf",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/uploadSOPPdf`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// upload sop section image
export const uploadSOPSectionImg = createAsyncThunk(
  "SOPSection/UploadImg",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionImage`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// upload sop section PDF
export const uploadSOPSectionPdf = createAsyncThunk(
  "SOPSection/UploadPDF",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionPdf`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// upload sop task images
export const uploadSOPTaskImg = createAsyncThunk(
  "SOPTask/UploadImg",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskImage`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// upload sop Task PDF
export const uploadSOPTaskPdf = createAsyncThunk(
  "SOPTask/UploadPdf",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskPdf`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// =============================================== USE SOP APIS ============================================

// get data from  useSOP
export const getUseSOPData = createAsyncThunk(
  "useSOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { userId, page, size, sortBy, sortOrder , eventCode} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { userId, page, size, sortBy, sortOrder , eventCode},
      };

      const responce = await axios.get(
        `${serviceURL}/useOfSOPs/getAllUserSOPRecords`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// save Data in SOP
export const saveUseSOPTemplate = createAsyncThunk(
  "useSOP/createTemplate",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { sopId , scheduleId , eventCode} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { sopId , scheduleId , eventCode},
      };

      const responce = await axios.post(
        `${serviceURL}/useOfSOPs/useSOP`,
        {},
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUseSOPData = createAsyncThunk(
  "useSOP/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { files, scheduleId , eventCode ,  ...restData } = data;
      const state = getState();
      const token = state.auth.token;

      const formData = new FormData();
      formData.append("record", JSON.stringify(restData));

      // Append each file to FormData properly
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      // Construct query parameters properly
      const queryParams = new URLSearchParams();
      if (scheduleId) {
        queryParams.append("scheduleId", scheduleId);
      }
      if (eventCode) {
        queryParams.append("eventCode", eventCode);
      }

      const config = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.post(
        `${serviceURL}/useOfSOPs/${restData.id}?${queryParams.toString()}`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// update Data in SOP
export const updateUseSOPData = createAsyncThunk("useSOP/update", async (data, { rejectWithValue, getState }) => {
    try {
      const { files, ...restData } = data;
        const state = getState();
        const token = state.auth.token;
  
        const formData = new FormData();
        formData.append("record", JSON.stringify(restData));
  
        // Append each file to FormData properly
        if (files && files.length > 0) {
          files.forEach((file) => {
            formData.append("files", file);
          });
        }
  
        // Construct query parameters properly
        const queryParams = new URLSearchParams();
  
        const config = { headers: { Authorization: `Bearer ${token}` } };
  
        const response = await axios.put(
          `${serviceURL}/useOfSOPs/updateExistingUserSOP/${restData.id}?${queryParams.toString()}`,
          formData,
          config
        );
  
        return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from SOP
export const deleteUseSOPData = createAsyncThunk(
  "useSOP/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/useOfSOPs/${data.sopId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================ Assign and Favorite SOP API =============================================

// Save Assign SOP Data
export const saveAssignSOP = createAsyncThunk(
  "assignSOP/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { myUserId, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfSOPs/assign`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Save Favorite SOP Data
export const saveFavoriteSOP = createAsyncThunk(
  "FavoriteSOP/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { isRemove, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { isRemove: isRemove },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfSOPs/markAsFavSOP`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get data from  useSOP
export const getFavAndAssignedSOP = createAsyncThunk(
  "favAndAssignedSOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.get(
        `${serviceURL}/useOfSOPs/getAllFavAndAssignedSOPs/${data.userId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  =========================================  manual APIS ==========================================================================================================================================================================================================================================================================================================================

// get data from Manual
export const getManualData = createAsyncThunk(
  "Manual/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/manuals/getAllManuals`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// save Data in Manual
export const saveManualData = createAsyncThunk(
  "Manual/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/manuals/createManual`,
        data,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update Data in Manual
export const updateManualData = createAsyncThunk(
  "Manual/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { manualId, ...restData } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.put(
        `${serviceURL}/manuals/${manualId}`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from Manual
export const deleteManualData = createAsyncThunk(
  "Manual/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/manuals/${data.id}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//================================== Manual PDF============================

// upload Manual PDF
export const uploadManualPdf = createAsyncThunk(
  "Manual/UploadPdf",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/manuals/${data.manualId}/uploadManual`,
        data.formData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// =============================================== USE Manual APIS ============================================

// get data from  useManual
export const getUseManualData = createAsyncThunk(
  "useManual/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.get(
        `${serviceURL}/Manuals/useOfManuals/${data.id}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// save Data in Manual
export const saveUseManualData = createAsyncThunk(
  "useManual/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { manualId, userName, ...restData } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { manualId: manualId, username: userName },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfManuals/useManual`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update Data in Manual
export const updateUseManualData = createAsyncThunk(
  "useManual/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { manualId, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.put(
        `${serviceURL}/useOfManuals/${manualId}`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete data from Manual
export const deleteUseManualData = createAsyncThunk(
  "useManual/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.delete(
        `${serviceURL}/useOfManuals/${data.manualId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================ Assign and Favorite Manual API =============================================

// Save Assign Manual Data
export const saveAssignManual = createAsyncThunk(
  "assignManual/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { myUserId, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfManuals/assign`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Save Favorite Manual Data
export const saveFavoriteManual = createAsyncThunk(
  "FavoriteManual/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { isRemove, ...restData } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { isRemove: isRemove },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfManuals/markAsFavManual`,
        restData,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get data from  useManual
export const getFavAndAssignedManual = createAsyncThunk(
  "favAndAssignedManual/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const responce = await axios.get(
        `${serviceURL}/useOfManuals/getAllFavAndAssignedManuals/${data.userId}`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export { CheckListSlice };

export const { resetCheckListState } = CheckListSlice.actions;

// {
//     "id": "6791ffbf5b8a7a1deace6d9a",
//     "userId": null,
//     "checklistId": "6791ffa75b8a7a1deace6d99",
//     "version": null,
//     "comment": null,
//     "sections": [
//         {
//             "key": "e4e45af5-7211-4e9e-b6c1-e8c93a69cbf3",
//             "tasks": [
//                 {
//                     "key": "c55c3774-3221-4161-a7a2-356b05c11e7a",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "49cd31fa-bbd1-46f6-abfd-a0072a2e230b",
//                             "tick": true
//                         }
//                     ]
//                 },
//                 {
//                     "key": "f2fc2349-3847-4f86-b051-04125b12064f",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "f37387bd-ff59-4c29-b7c4-7406cb58060e",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "key": "5cee3440-316a-4971-8d50-12cf70dc7286",
//             "tasks": [
//                 {
//                     "key": "5a000517-df65-455f-ba91-ed5bcae33032",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "e483e1dc-79a7-4627-aea1-a198aeb98584",
//                             "tick": true
//                         }
//                     ]
//                 },
//                 {
//                     "key": "2c0ab7de-8fa9-4f46-9538-5190057725e4",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "0d7aa1e0-a9f9-4a65-8f4b-aae714aed5f1",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "attachmentUrls": null,
//     "createdBy": "pankajkumar@technumopus.com",
//     "updatedBy": "pankajkumar@technumopus.com",
//     "deletedBy": null,
//     "createdAt": "2025-01-23T08:36:55.991+00:00",
//     "updatedAt": "2025-01-23T08:37:19.652+00:00",
//     "deletedAt": null,
//     "deleted": false
// }

// {
//     "id": "679200415b8a7a1deace6d9c",
//     "userId": null,
//     "checklistId": "679200345b8a7a1deace6d9b",
//     "version": null,
//     "comment": null,
//     "sections": [
//         {
//             "key": "df221bcb-479d-4f05-8c6f-313d9ab2ac85",
//             "tasks": [
//                 {
//                     "key": "50ecd465-dfeb-4a67-b6c6-8f53ec8269c7",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "70adfe96-5ea3-4eeb-bdfa-9bee69446751",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "attachmentUrls": null,
//     "createdBy": "pankajkumar@technumopus.com",
//     "updatedBy": "pankajkumar@technumopus.com",
//     "deletedBy": null,
//     "createdAt": "2025-01-23T08:39:16.459+00:00",
//     "updatedAt": "2025-01-23T08:39:29.753+00:00",
//     "deletedAt": null,
//     "deleted": false
// }

/*==================================================== Separate API for All Tab(Checklist) =============================================================*/

export const getAllFavCheckListData = createAsyncThunk(
  "favCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/checklists/getAllFavChecklists`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAssignedCheckListData = createAsyncThunk(
  "assignedCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/checklists/getAllAssignedChecklists`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllMyCheckListData = createAsyncThunk(
  "myCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/checklists/getAllMyChecklists`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// created by me checklists
export const getAllCreatedByMeCheckListData = createAsyncThunk(
  "createdByMeCheckList/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business ,myFunction} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder,createdBy,assignedBy,approvedBy,business ,myFunction},
      };

      const responce = await axios.get(
        `${serviceURL}/checklists/getCreatedByMeChecklists`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*==================================================== Separate API for All Tab(SOP) =============================================================*/

export const getAllFavSOP = createAsyncThunk(
  "favSOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/sops/getAllFavSOPs`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAssignedSOP = createAsyncThunk(
  "assignedSOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/sops/getAllAssignedSOPs`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllMySOP = createAsyncThunk(
  "mySOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/sops/getAllMySOPs`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// created by me SOP data
export const getAllCreatedByMeSOPData = createAsyncThunk(
  "createdByMeSOP/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business, myFunction },
      };

      const responce = await axios.get(
        `${serviceURL}/sops/getCreatedByMeSOPs`,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserSharedWithChecklists = createAsyncThunk(
  "userharedwithchecklist/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { recordId, sharedWith } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { recordId },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfChecklists/sharedWith`,
        sharedWith,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get all shared user checklist records
export const getSharedUserChecklistRecord = createAsyncThunk(
  "shareduserchecklist/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const {
        sharedWith,
        page = 0,
        size = 4,
        sortBy = "updatedAt",
        sortOrder = "asc",
      } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          sharedWith,
          page,
          size,
          sortBy,
          sortOrder,
        },
      };

      console.log("Request config:", config); // Debug the request
      const response = await axios.get(
        `${serviceURL}/useOfChecklists/getSharedUserChecklistRecords`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getSharedUserSOPRecord = createAsyncThunk(
  "sharedusersop/get",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, sharedWith } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { sharedWith, page, size, sortBy, sortOrder },
      };

      console.log("Request config:", config); // Debug the request
      const responce = await axios.get(
        `${serviceURL}/useOfSOPs/getSharedUserSOPRecords`,
        config
      );
      return responce.data;
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserSharedWithSOPs = createAsyncThunk(
  "usersharedwithsop/save",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { recordId, sharedWith } = data;

      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { recordId },
      };

      const responce = await axios.post(
        `${serviceURL}/useOfSOPs/sharedWith`,
        sharedWith,
        config
      );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


//  ======================= Style Preferences   =======================
export const getUserStylePreferences = createAsyncThunk("style/get", async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};

      const responce = await axios.get(`${serviceURL}/checklists/getUserStyleByUserId`,config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserPrefChecklistsBulk = createAsyncThunk( "userpreferencebulk/save", async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};

      const responce = await axios.post(`${serviceURL}/checklists/resetUserStyles`, data, config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserPrefChecklists = createAsyncThunk("userpreference/save", async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};

      const responce = await axios.post(`${serviceURL}/checklists/addUserStyle`, data, config);
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);








export const getTopMetrics = createAsyncThunk(
  "dashboard/topMetrics",
  async (params = {}, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { period = "overall", page = 0, size = 10, salaryId } = params;
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { period, page, size, salaryId },
      };

      const response = await axios.get(`${serviceURL}/dashboard/top-metrics`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);










// Get reportees for a supervisor
export const getReportees = createAsyncThunk( "manage/reportees", async (params = {}, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { supervisorEmployeeID } = params;
      const config = { 
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",},
        params: { supervisorEmployeeID},
      };

      const response = await axios.get(`${serviceURL}/manage/reportS`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
); 


// Get user metrics for a graph date-wise
export const getUserMetrics = createAsyncThunk(
  "dashboard/userMetrics",
  async (params = {}, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { period, username, page = 0, size = 10 } = params;
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { period, username, page, size },
      };

      const response = await axios.get(`${serviceURL}/dashboard/user-metrics`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// ================================================================= Support API ================================================================= //

export const supportAPI = createAsyncThunk("supportmail/save", async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = { headers: { Authorization: `Bearer ${token}`, }};

      // Create FormData object to handle file upload
      const formData = new FormData();
      formData.append("issue", data.issue);
      formData.append("description", data.description);
      if (data?.attachment) {
        formData.append("attachment", data.attachment); // File object
      }
      formData.append("userEmail", data.userEmail);
      formData.append("userName", data.userName);
      formData.append("employeeID", data.employeeID);
      formData.append("applicationName", data.applicationName);

      const response = await axios.post(`${serviceURL}/auth/support`, formData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);







// Get top metrics for a reportee
export const getReporteeMetrics = createAsyncThunk(
  "dashboard/reporteeMetrics",
  async (params = {}, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { period, empid, page = 0, size = 10 } = params;
      const config = {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { period, empid, page, size },
      };

      const response = await axios.get(
        `${serviceURL}/dashboard/top-metrics/reportee`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


// ================================================================= Checklists Approval APIs ================================================================= //


export const submitChecklistForApproval = createAsyncThunk(" submitchecklist/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { checklistId, approverEmail } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
      params: { approverEmail: approverEmail},
    };

    const response = await axios.post(`${serviceURL}/checklists/${checklistId}/submit`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const getChecklistApprovalsDataForParticular = createAsyncThunk( "approvalchecklistdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const {approver } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",}, 
      params: { approver: approver},
     };

    const response = await axios.get(`${serviceURL}/checklists/getApprovalsDataForParticular`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
);

export const reqChangeChecklistForApproval = createAsyncThunk(" reqchangechecklist/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
    };

    const response = await axios.post(`${serviceURL}/checklists/approvals/${approvalId}/request-changes`, data,config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const ChecklistApproved = createAsyncThunk(" approvechecklist/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};

    const response = await axios.post(`${serviceURL}/checklists/approvals/${approvalId}/approve`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
); 

export const getAllChecklistsApprovals = createAsyncThunk( "checklistsapprovaltdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const { page, size, sortBy, sortOrder, createdBy } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",}, 
      params: { page, size, sortBy, sortOrder, createdBy},
     };

    const response = await axios.get(`${serviceURL}/checklists/getAllChecklistApprovals`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
);

// ================================================================= SOP Approval APIs ================================================================= //


export const submitSOPForApproval = createAsyncThunk(" submitsops/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { sopId, approverEmail } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
      params: { approverEmail: approverEmail},
    };

    const response = await axios.post(`${serviceURL}/sops/${sopId}/submit`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const getSOPApprovalsDataForParticular = createAsyncThunk( "approvalsopdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const {approver } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { 
      headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",},
      params: { approver: approver},
    };

    const response = await axios.get(`${serviceURL}/sops/getApprovalsDataForParticular`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
); 


export const reqChangeSOPForApproval = createAsyncThunk(" reqchangesop/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
    };

    const response = await axios.post(`${serviceURL}/sops/approvals/${approvalId}/request-changes`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const SOPAproved = createAsyncThunk(" approvesop/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;

    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};

    const response = await axios.post(`${serviceURL}/sops/approvals/${approvalId}/approve`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const getAllSOPApprovals = createAsyncThunk( "sopapprovaltdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const { page, size, sortBy, sortOrder, filters } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",}, 
      params: { page, size, sortBy, sortOrder, filters},
     };

    const response = await axios.get(`${serviceURL}/sops/getAllSOPApprovals`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
);

// ================================================================= Checklists Schedule APIs ================================================================= //


export const ChecklistScheduleCreate = createAsyncThunk( "schedule/save", async (data, { rejectWithValue, getState }) => {
    try {
      const {type, typeId} = data;
      const state = getState();
      const token = state.auth.token;

      const config = { headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json", },};

      const response = await axios.post(`${serviceURL}/checklistSchedules/create/${type}/${typeId}`,data, config );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to create schedule");
    }
  }
);


export const ChecklistScheduleUpdate = createAsyncThunk( "schedule/update", async (data, { rejectWithValue, getState }) => {
    try {
      const { scheduleId, ...updateData } = data; // Extract scheduleId and the rest as update data
      const state = getState();
      const token = state.auth.token;

      const config = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", }};

      const response = await axios.post(`${serviceURL}/checklistSchedules/update/${scheduleId}`, updateData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const ChecklistSchedules = createAsyncThunk( "schedule/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder , assignedTo , assignedBy , sharedWith} = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params: { page, size, sortBy, sortOrder, assignedTo , assignedBy , sharedWith},
      };

      const response = await axios.get(`${serviceURL}/checklistSchedules/all`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


export const assignedToMeSchedules = createAsyncThunk( "schedule/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder} = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = { headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json" },params:{
        page, size, sortBy, sortOrder
      }};

      const response = await axios.get(`${serviceURL}/checklistSchedules/assigned-to-me`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


export const assignedByMeSchedules = createAsyncThunk( "schedule/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder} = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = { headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json" },params:{
        page, size, sortBy, sortOrder
      }};

      const response = await axios.get(`${serviceURL}/checklistSchedules/assigned-by-me`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const sharedWithMeSchedules = createAsyncThunk( "schedule/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder} = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = { headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json" },params:{
        page, size, sortBy, sortOrder
      }};

      const response = await axios.get(`${serviceURL}/checklistSchedules/shared-with-me`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);



export const pastEvents = createAsyncThunk( "events/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith } = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params: { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith},
      };

      const response = await axios.get(`${serviceURL}/checklistSchedules/events/past`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const futureEvents = createAsyncThunk( "futureevents/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith } = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params: { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith},
      };

      const response = await axios.get(`${serviceURL}/checklistSchedules/events/future`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const allEvents = createAsyncThunk( "allevents/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith } = data;
      console.log(data)
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params: { page, size, sortBy, sortOrder,assignedBy,assignedTo,sharedWith},
      };

      const response = await axios.get(`${serviceURL}/checklistSchedules/events/all`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


export const getOneSchedule = createAsyncThunk("schedule/getOne",async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }};

      const response = await axios.get(`${serviceURL}/checklistSchedules/${data.scheduleId}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


// delete data from CheckList
export const deleteSchedule = createAsyncThunk("schedule/delete", async (data, { rejectWithValue, getState }) => {
  try {
      const state = getState();
      const token = state.auth.token;
      const config = { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", }};

      const responce = await axios.delete(`${serviceURL}/checklistSchedules/delete/${data.scheduleId}`, config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getOneCombinedData = createAsyncThunk("combined/getOne",async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        params : {type : data.type, typeId : data.typeId}
      };

      const response = await axios.get(`${serviceURL}/checklistSchedules/checklistDataUsingTypeAndTypeId`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


// ================================================================= Support Ticket APIs ================================================================= //

export const allSupportTickets = createAsyncThunk( "alltickets/get", async (data, { rejectWithValue, getState }) => {
  try {
    const { page, size, sortBy, sortOrder , ticket_number , support_team_category , application_name,submitted_by,status,assignment,created_at} = data;
    console.log(data)
    const state = getState();
    const token = state.auth.token;
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      params: { page, size, sortBy, sortOrder,  ticket_number , support_team_category , application_name,submitted_by,status,assignment,created_at},
    };

    const response = await axios.get(`${serviceURL}/auth/support/tickets`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
);




// update Data in Ticket
export const updateTicket = createAsyncThunk(
  "checkList/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const { ticketNumber, ...restData } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${serviceURL}/auth/support/ticket/${ticketNumber}`,
        restData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const updateTicket = createAsyncThunk(
//   "checkList/update",
//   async (data, { rejectWithValue, getState }) => {
//     try {
//       const { id, ...restData } = data;
//       const state = getState();
//       const token = state.auth.token;
//       const config = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       };

//       const responce = await axios.post(
//         `${serviceURL}/auth/support/ticket/${id}`,
//         restData,
//         config
//       );
//       return responce.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const saveTicket  = createAsyncThunk("supportmail/save", async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const token = state.auth.token;
    const config = { headers: { Authorization: `Bearer ${token}`, }};

    // Create FormData object to handle file upload
    const formData = new FormData();
   
    formData.append("issueTitle", data.issueTitle);
    formData.append("issueDetails", data.issueDetails);
    formData.append("issueType", data.issueType);
    formData.append("supportTeamCategory", data.supportTeamCategory);

    formData.append("applicationName", data.applicationName);
    if (data?.attachment) {
      formData.append("attachment", data.attachment); // File object
    }

    formData.append("priority", data.priority);
    
    if (data.assignment && data.assignment.length > 0) {
      formData.append("assignment", JSON.stringify(data.assignment));
    }
    
    if (data.comments && data.comments.length > 0) {
      formData.append("comments", JSON.stringify(data.comments));
    }


   

    const response = await axios.post(`${serviceURL}/auth/support/ticket`, formData, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);



/*==================================================== Separate API for All Tab(Manual) =============================================================*/

export const getAllFavManualData = createAsyncThunk( "favManual/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get( `${serviceURL}/manuals/getAllFavManuals`, config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllMyManualsData = createAsyncThunk( "myManual/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction } = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get( `${serviceURL}/manuals/getAllMyManuals`, config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// created by me checklists
export const getAllCreatedByMeManualsData = createAsyncThunk( "createdByMeCheckList/get", async (data, { rejectWithValue, getState }) => {
    try {
      const { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business ,myFunction} = data;
      const state = getState();
      const token = state.auth.token;
      const config = {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", },
        params: { page, size, sortBy, sortOrder, createdBy,assignedBy,approvedBy,business,myFunction },
      };

      const responce = await axios.get(`${serviceURL}/manuals/getCreatedByMeManuals`, config );
      return responce.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================= Manual Approval APIs ================================================================= //


export const submitManualForApproval = createAsyncThunk(" submitmanuals/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { manualId, approverEmail } = data;
    const state = getState();
    const token = state.auth.token;
    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
      params: { approverEmail: approverEmail},
    };

    const response = await axios.post(`${serviceURL}/manuals/${manualId}/submit`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const getManualApprovalsDataForParticular = createAsyncThunk( "approvalmanualdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const {approver } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { 
      headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",},
      params: { approver: approver},
    };

    const response = await axios.get(`${serviceURL}/manuals/getApprovalsDataForParticular`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
); 


export const reqChangeManualForApproval = createAsyncThunk(" reqchangemanual/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;
    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",},
    };

    const response = await axios.post(`${serviceURL}/manuals/approvals/${approvalId}/request-changes`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const ManualAproved = createAsyncThunk(" approvemanual/save", async (data, { rejectWithValue, getState }) => {
  try {
    const { approvalId } = data;
    const state = getState();
    const token = state.auth.token;
    const config = {headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json",}};
    const response = await axios.post(`${serviceURL}/manuals/approvals/${approvalId}/approve`, {},config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);

export const getAllManualApprovals = createAsyncThunk( "manualapprovaltdata/get", async (data, { rejectWithValue, getState }) => {
  try {
    const { page, size, sortBy, sortOrder, filters } = data;
    const state = getState();
    const token = state.auth.token;
    const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json",}, 
      params: { page, size, sortBy, sortOrder, filters},
     };

    const response = await axios.get(`${serviceURL}/manuals/getAllManualApprovals`, config);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Something went wrong");
  }
}
);

export const getOneManualData = createAsyncThunk("oneManual/get", async (data, { rejectWithValue, getState }) => {
  try {
    const { page, size, sortBy, sortOrder, filters } = data;
    const state = getState();
    const token = state.auth.token;
    const config = {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", },
      params: { page, size, sortBy, sortOrder, filters },
    };

    // Convert array to query string format
    const params = new URLSearchParams();
    data.manualIds.forEach(id => params.append("manualIds", id));

    const responce = await axios.get(`${serviceURL}/manuals/by-ids?${params.toString()}`, config);
    return responce.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);